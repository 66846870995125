// Generated by Framer (8a81902)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);
const MaterialControls = getPropertyControls(Material);

const enabledGestures = {cR5H9c4Wb: {hover: true}};

const cycleOrder = ["cR5H9c4Wb"];

const variantClassNames = {cR5H9c4Wb: "framer-v-el8xql"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, icon, id, link, width, ...props}) => { return {...props, Ga4XD6inS: icon ?? props.Ga4XD6inS ?? "Twitter", n98VOgYIP: link ?? props.n98VOgYIP} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;icon?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Ga4XD6inS, n98VOgYIP, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cR5H9c4Wb", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-YdXn7", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={n98VOgYIP} openInNewTab><motion.a {...restProps} className={`${cx("framer-el8xql", className)} framer-a0qk21`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"cR5H9c4Wb"} ref={ref} style={{...style}} {...addPropertyOverrides({"cR5H9c4Wb-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-qri5vk-container"} layoutDependency={layoutDependency} layoutId={"JS0g1KcqQ-container"}><Material color={"rgba(255, 255, 255, 0.25)"} height={"100%"} iconSearch={"Home"} iconSelection={Ga4XD6inS} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"JS0g1KcqQ"} layoutId={"JS0g1KcqQ"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"} {...addPropertyOverrides({"cR5H9c4Wb-hover": {color: "var(--token-86117d10-82c0-48ca-83b9-ad514639102d, rgb(255, 255, 255)) /* {\"name\":\"white 100\"} */"}}, baseVariant, gestureVariant)}/></motion.div></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-YdXn7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YdXn7 .framer-a0qk21 { display: block; }", ".framer-YdXn7 .framer-el8xql { height: 24px; overflow: hidden; position: relative; text-decoration: none; width: 24px; }", ".framer-YdXn7 .framer-qri5vk-container { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }", ".framer-YdXn7 .framer-v-el8xql .framer-el8xql { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"hBMhP98j3":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Ga4XD6inS":"icon","n98VOgYIP":"link"}
 * @framerImmutableVariables false
 */
const FramerN7nh62B9F: React.ComponentType<Props> = withCSS(Component, css, "framer-YdXn7") as typeof Component;
export default FramerN7nh62B9F;

FramerN7nh62B9F.displayName = "Social";

FramerN7nh62B9F.defaultProps = {height: 24, width: 24};

addPropertyControls(FramerN7nh62B9F, {Ga4XD6inS: MaterialControls?.["iconSelection"] && {...MaterialControls["iconSelection"], defaultValue: "Twitter", hidden: undefined, title: "Icon"}, n98VOgYIP: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerN7nh62B9F, [...MaterialFonts])